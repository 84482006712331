<div class="container mt-5">
  <div class="title-and-button">
    <h3>Pending tasks</h3>
    <button *ngIf="myRole == 'chatter'"
      class="btn btn-primary"
      [disabled]="!audience"
      (click)="open(content)"
    >
      Create new task
    </button>
  </div>

  <div ngbAccordion [closeOthers]="true">
    <h3 class="title-no-pending-tasks" *ngIf="(myRole == 'creator') && (pendingTasks.length == 0)">Good news! All tasks completed.</h3>
    <div *ngFor="let task of pendingTasks" ngbAccordionItem>
      <h3 ngbAccordionHeader>
        <button ngbAccordionButton><span class="accordion-header-flex"><span>{{ task.date | date: 'dd/MM/YYYY, HH:mm' }}</span><span>{{ task.creatorName }}</span><span *ngIf="task.status == 'pending'" class="badge text-bg-warning">{{ task.status }}</span><span *ngIf="task.status == 'completed'" class="badge text-bg-success">{{ task.status }}</span></span></button>
      </h3>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody class="description-container">
          <ng-template>
            <p>
              {{ task.description }}
            </p>
            <span *ngIf="myRole == 'creator'">
              <ul>
                <li *ngFor="let file of task.files">
                  <a href="{{apiLink}}/tasks/file/{{file}}/content" download>{{ file }}</a>
                </li>
              </ul>
            </span>
            <p class="buttons-option">
              <button *ngIf="myRole == 'creator'"
                class="btn btn-primary"
                [disabled]="!audience"
                (click)="fileInput.click()"
              >
                Upload file
              </button><br>
              <button *ngIf="(myRole == 'creator') && (task.files.length > 0)"
                class="btn btn-primary"
                [disabled]="!audience"
                (click)="markTaskAsCompleted(task._id)"
              >
                Mark as completed
              </button>
            </p>
            <input style="display: none;" #fileInput type="file" (change)="onFileSelected($event, task._id)" />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <br><br>

  <div *ngIf="myRole == 'chatter'" class="title-and-button">
    <h3>Completed tasks</h3>
  </div>
  <div *ngIf="myRole == 'chatter'" ngbAccordion [closeOthers]="true">
      <div *ngFor="let task of completedTasks" ngbAccordionItem>
        <h3 ngbAccordionHeader>
          <button ngbAccordionButton><span class="accordion-header-flex"><span>{{ task.date | date: 'dd/MM/YYYY, HH:mm' }}</span><span>{{ task.creatorName }}</span><span *ngIf="task.status == 'pending'" class="badge text-bg-warning">{{ task.status }}</span><span *ngIf="task.status == 'completed'" class="badge text-bg-success">{{ task.status }}</span></span></button>
        </h3>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody class="description-container">
            <ng-template>
              <p>
                {{ task.description }}
              </p>
              <span>
                <ul>
                  <li *ngFor="let file of task.files">
                    <a href="{{apiLink}}/tasks/file/{{file}}/content" download>{{ file }}</a>
                  </li>
                </ul>
              </span>
              <p>
                <button *ngIf="myRole == 'chatter'"
                  class="btn btn-primary"
                  [disabled]="!audience"
                  (click)="markTaskAsUsed(task._id)"
                >
                  Mark as used
                </button>
              </p>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <br>

  <div class="result-block-container" *ngIf="responseJson">
    <div class="result-block" [ngClass]="{ show: !!responseJson }">
      <h6 class="muted">Result</h6>
      <pre>
        <code class="js rounded" [highlight]="responseJson"></code>
      </pre>
    </div>
  </div>
</div>

<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Create new task</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
				<label for="dateOfBirth">Select creator</label>
				<div class="form-group">
          <div ngbDropdown class="d-inline-block">
            <select #creatorNameSelected type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
              <option *ngFor="let creatorName of creatorNames" ngbDropdownItem value="{{creatorName}}">{{ creatorName }}</option>
            </select>
          </div>
				</div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Task description</label>
          <textarea #taskDescription class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
			</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="createTask(creatorNameSelected.value, taskDescription.value)">Send task</button>
	</div>
</ng-template>