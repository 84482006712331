import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  public time = new Date();

  ngOnInit() {
    setInterval(() => {
      this.time = new Date()
    }, 10000)
  }
}
