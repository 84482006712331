import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import config from '../../auth_config.json';
import { AuthService } from '@auth0/auth0-angular';
import { switchMap } from 'rxjs/operators';

const api = 'https://api-rda-chat-tool-1336a7dedebb.herokuapp.com';


@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    public auth: AuthService
  ) {}

  public apiLink = api;

  getToken() {
    return this.auth.getAccessTokenSilently();
  }

  getMyRole() {
    return this.getToken().pipe(
      switchMap((token) => {
        return this.http.get(`${api}/users/me`, {
            headers: { Authorization: `Bearer ${token}` }
        });
      })
    );
  }
  
  createTask(creatorName: string, taskDescription: string) {
    let date = Date.now().toString();
    return this.getToken().pipe(
      switchMap((token) => {
        return this.http.post(`${api}/tasks/create`, {
          "date": date,
          "creator": creatorName,
          "description": taskDescription
        }, {
            headers: { Authorization: `Bearer ${token}`, }
        });
      })
    );
  }

  getTasks() {
    return this.getToken().pipe(
      switchMap((token) => {
        return this.http.get(`${api}/tasks`, {
            headers: { Authorization: `Bearer ${token}` }
        });
      })
    );
  }

  getCreatorName(id: string) {
    return this.getToken().pipe(
      switchMap((token) => {
        return this.http.get(`${api}/users/me/creators/name/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
      })
    );
  }

  getCreators() {
    return this.getToken().pipe(
      switchMap((token) => {
        return this.http.get(`${api}/users/me/creators`, {
            headers: { Authorization: `Bearer ${token}` }
        });
      })
    );
  }

  uploadFile(file: File, taskId: string) {
    let date = Date.now().toString();
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('taskId', taskId);
    return this.getToken().pipe(
      switchMap((token) => {
        return this.http.post(`${api}/files/upload`, formData, {
            headers: { Authorization: `Bearer ${token}`, }
        });
      })
    );
  }

  markTaskAsCompleted(taskId: string) {
    return this.getToken().pipe(
      switchMap((token) => {
        return this.http.get(`${api}/tasks/completed/${taskId}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
      })
    );
  }

  markTaskAsUsed(taskId: string) {
    return this.getToken().pipe(
      switchMap((token) => {
        return this.http.get(`${api}/tasks/used/${taskId}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
      })
    );
  }
}
