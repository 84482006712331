import { Component, TemplateRef, inject } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent {
  responseJson: string;
  audience: string | undefined;
  hasApiError = false;

  constructor(
    private api: ApiService,
    private configFactory: AuthClientConfig,
    private toastr: ToastrService
  ) {
    this.audience = this.configFactory.get()?.authorizationParams.audience;
  }
  selectedFile: File;
  public apiLink;

  public pendingTasks: any = [];
  public completedTasks: any = [];
  private modalService = inject(NgbModal);
	closeResult = '';
  public creatorNames: any = [];
  public myRole: string;

  ngOnInit() {
    this.apiLink = this.api.apiLink;
    this.getMyRole();
    this.pendingTasks = [];
    this.completedTasks = [];
    this.creatorNames = [];
    
  }

  getMyRole() {
    this.api.getMyRole().subscribe(
      (data) => {
        this.myRole = data['role'];
        this.getTasks();
        this.getCreatorNames();
      },
      (e) => {
        this.toastr.error('Error getting role. Contact support.')
      }
    )
  }

  getCreatorNames() {
    if (this.myRole != 'chatter') {
      return;
    }
    this.api.getCreators().subscribe(
      (data: any) => {
        for (let creatorId of data) {
          this.api.getCreatorName(creatorId).subscribe(
            (data) => {
              let name = data['name'];
              this.creatorNames.push(name)
            }
          )
        }
      }
    )
  }

  getTasks() {
    this.api.getTasks().subscribe(
      (data: any) => {
        for (let task of data) {
          this.api.getCreatorName(task.creator).subscribe(
            (data: any) => {
              task.creatorName = data.name;
            }
          )
          if (task.status == 'pending') {
            this.pendingTasks.push(task);
          } else if (task.status == 'completed') {
            this.completedTasks.push(task);
          }
        }
      },
      (e) => {
        console.error(e);
      }
    )
  }

  createTask(creatorName: string, taskDescription: string) {
    if (creatorName == "" || taskDescription == "") {
      this.toastr.info('Complete all form inputs.')
      return;
    }

    (this.api.createTask(creatorName, taskDescription)).subscribe(
      (data: any) => {
        this.modalService.dismissAll();
        this.toastr.success('Task sended.')
        this.ngOnInit();
      },
      (e) => {
        console.error(e);
        this.toastr.error('Error sending task. Contact support.')
      }
    )
  }

  onFileSelected(event, taskId: string) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      this.uploadFile(taskId);
    }
  }

  uploadFile(taskId:string) {
    this.api.uploadFile(this.selectedFile, taskId).subscribe(
      response => {
        this.toastr.success('File uploaded.')
        console.log(this.pendingTasks)
        const obj = this.pendingTasks.find((obj => obj._id == taskId));
        if (obj) {
          obj.files.push(response['files'][response['files'].length - 1]);
        }
      },
      error => {
        this.toastr.error('Error uploading file. Contact support.')
        console.error('Error al subir archivo', error);
      }
    );
  }

  markTaskAsCompleted(taskId: string) {
    this.api.markTaskAsCompleted(taskId).subscribe(
      (data) => {
        this.toastr.success('Marked as complete.')
        this.ngOnInit();
      },
      (e) => {
        this.toastr.error('Error marking as completed. Contact support.')
      }
    )
  }

  markTaskAsUsed(taskId: string) {
    this.api.markTaskAsUsed(taskId).subscribe(
      (data) => {
        this.toastr.success('Marked as used.')
        this.ngOnInit();
      },
      (e) => {
        this.toastr.error('Error marking as completed. Contact support.')
      }
    )
  }

  open(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
        
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}
}
